export enum SonicFeature {
  FullStory = 'isFullStoryEnabled',
  ContentInsight = 'isContentInsightEnabled',
  LocalizationReporting = 'isLocalizationReportingEnabled',
  StudioAccess = 'isStudioAccessEnabled',
  ContentVisualization = 'isContentVisualizationEnabled',
  GeneralRights = 'isGeneralRightsEnabled',
  Genres = 'isGenresEnabled',
  Images = 'isImagesEnabled',
  IMSForContentRatings = 'isIMSForContentRatingsEnabled',
  IMSForContentDescriptors = 'isIMSForContentDescriptorsEnabled',
  IMSForGenres = 'isIMSForGenresEnabled',
  IMSForLivestreams = 'isIMSForLivestreamsEnabled',
  IMSForTaxonomyNodes = 'isIMSForTaxonomyNodesEnabled',
  IMSForVideoAssets = 'isIMSForVideoAssetsEnabled',
  ListableAndPlayableAppNames = 'isListableAndPlayableAppNamesEnabled',
  ListableAndPlayableTerritories = 'isListableAndPlayableTerritoriesEnabled',
  LiveVideos = 'isLiveVideosEnabled',
  Localizations = 'isLocalizationsEnabled',
  LookerLinks = 'isLookerLinksEnabled',
  MediaDownload = 'isMediaDownloadEnabled',
  Offers = 'isOffersEnabled',
  OffersReadOnly = 'isOffersReadOnlyEnabled',
  OTP = 'isOTPEnabled',
  PersonalizedAIRecommendations = 'isPersonalizedAIRecommendationsEnabled',
  HopperRecommendations = 'isHopperRecommendationsEnabled',
  ProductLineFilterForTaxonomyNodeImg = 'isProductLineFilterForTaxonomyNodeImgEnabled',
  WatchAgainKind = 'isWatchAgainKindEnabled',
  Providers = 'isProvidersEnabled',
  RelatedSiteBuilderObjects = 'isRelatedSiteBuilderObjectsEnabled',
  SourceSystemId = 'isSourceSystemIdEnabled',
  Subtitles = 'isSubtitlesEnabled',
  Tags = 'isTagsEnabled',
  Taxonomies = 'isTaxonomiesEnabled',
  TVListings = 'isTVListingsEnabled',
  UserCapabilitiesV1 = 'isUserCapabilitiesV1Enabled',
  UserCapabilitiesV2 = 'isUserCapabilitiesV2Enabled',
  CapabilitiesBolt = 'isCapabilitiesBoltEnabled',
  CapabilitiesLegacy = 'isCapabilitiesLegacyEnabled',
  VideoAssetUpload = 'isVideoAssetUploadEnabled',
  VMS = 'isVMSEnabled',
  MainTerritoryCodeAndBrandIdOnTermKinds = 'isMainTerritoryCodeAndBrandIdOnTermKindsEnabled',
  ContentProtection = 'isContentProtectionEnabled',
  DTCRARContracts = 'isDTCRARContractsEnabled',
  DTCRARProducts = 'isDTCRARProductsEnabled',
  SiteBuilder = 'isSiteBuilderEnabled',
  PricePlanProvidersWhenOngoing = 'isPricePlanProvidersWhenOngoingEnabled',
  ProviderAndSKUWhenSAMIntegration = 'isProviderAndSKUWhenSAMIntegrationEnabled',
  MergedCollections = 'isMergedCollectionsEnabled',
  SubscriptionJourney = 'isSubscriptionJourneyEnabled',
  RolesPermissions = 'isRolesPermissionsEnabled',
  StudioGlobalExpansion = 'isStudioGlobalExpansionEnabled',
  ModelComparison = 'isModelComparisonEnabled',
  ModelComparisonShowTypeFilter = 'isModelComparisonShowTypeFilterEnabled',
  Pinning = 'isPinningEnabled',
  CompassReranking = 'isCompassRerankingEnabled',
  HorizontalReranking = 'isHorizontalRerankingEnabled',
  PricePlanFilteringByBrand = 'isPricePlanFilteringByBrandEnabled',
  PricePlanSourceSystem = 'isPricePlanSourceSystemEnabled',
  PricePlanMarketingInfoCharacterLimit = 'isPricePlanMarketingInfoCharacterLimitEnabled',
  AudienceTargeting = 'isAudienceTargetingEnabled',
  AudienceTargetingFromCustomerSegments = 'isAudienceTargetingFromCustomerSegmentsEnabled',
  GauthPartners = 'isGauthPartnersEnabled',
  SiteBuilderTemplates = 'isSiteBuilderTemplatesEnabled',
  SiteBuilderTemplatesUsage = 'isSiteBuilderTemplatesUsageEnabled',
  DetokenizePaymentMethod = 'isDetokenizePaymentMethodEnabled',
  PayPerView = 'isPayPerViewEnabled',
  BoltSubscriptionJourney = 'isBoltSubscriptionJourneyEnabled',
  TerminateReasonNorwayDenmark = 'isTerminateReasonNorwayDenmarkEnabled',
  UserCommunication = 'isUserCommunicationEnabled',
  BrazeUserCommunication = 'isBrazeUserCommunicationEnabled',
  Legal = 'isLegalEnabled',
  Ratings = 'isRatingsEnabled',
  Search = 'isSearchEnabled',
  LabsBolt = 'isLabsBoltEnabled',
  LabsLegacy = 'isLabsLegacyEnabled',
  Packages = 'isPackagesEnabled',
  ShowTypes = 'isShowTypesEnabled',
  PushPinReranking = 'isPushPinRerankingEnabled',
  PackagesInCapabilities = 'isPackagesInCapabilitiesEnabled',
  PartnerSubscriptions = 'isPartnerSubscriptionsEnabled',
  SearchAutoCollection = 'isSearchAutoCollectionEnabled',
  BoltCapabilities = 'isBoltCapabilitiesEnabled',
  SiteBuilderCollectionSecondaryTitle = 'isSiteBuilderCollectionSecondaryTitleEnabled',
  SiteBuilderMyList = 'isSiteBuilderMyListEnabled',
  SiteBuilderRecentlyViewed = 'isSiteBuilderRecentlyViewedEnabled',
  SiteBuilderRecommendedShows = 'isSiteBuilderRecommendedShowsEnabled',
  SiteBuilderShowsWithExpiringEpisodes = 'isSiteBuilderShowsWithExpiringEpisodesEnabled',
  SiteBuilderVideoScheduleHour = 'isSiteBuilderVideoScheduleHourEnabled',
  SiteBuilderVideoTabbedScheduleGrid = 'isSiteBuilderVideoTabbedScheduleGridEnabled',
  SiteBuilderHideKindSortOptions = 'isSiteBuilderHideKindSortOptionsEnabled',
  SiteBuilderKindDisabled = 'isSiteBuilderKindDisabled',
  SiteBuilderVideoListingDisabled = 'isSiteBuilderVideoListingDisabled',
  SiteBuilderContinueWatching = 'isSiteBuilderContinueWatchingEnabled',
  SiteBuilderLegacyContinueWatching = 'isSiteBuilderLegacyContinueWatchingEnabled',
  SiteBuilderFilterByTaxonomyNodes = 'isSiteBuilderFilterByTaxonomyNodesEnabled',
  PartnerLinkCta = 'isPartnerLinkCtaEnabled',
  CollectionPersonalization = 'isCollectionPersonalizationEnabled',
  PasswordResetCopyToClipboard = 'isPasswordResetCopyToClipboardEnabled',
  LegacyContentSearch = 'isLegacyContentSearch',
  Badging = 'isBadgingEnabled',
  Avatars = 'isAvatarsEnabled',
  TaxonomyUsedByTypeCVNames = 'isTaxonomyUsedByTypeCVNamesEnabled',
  M10nFeatures = 'isM10nFeaturesEnabled',
  UserRoleChangeConfirmation = 'isUserRoleChangeConfirmationEnabled',
  Hotjar = 'isHotjarEnabled',
  Posthog = 'isPostHogEnabled',
  GSM = 'isGSMEnabled',
  UserIdentity = 'isUserIdentityEnabled',
  Collaborators = 'isCollaboratorsEnabled',
  OktaIdRegistration = 'isOktaIdRegistrationEnabled',
  ContractWithSidebar = 'ContractWithSidebar',
  IAPParentPricePlan = 'isIAPParentPricePlanEnabled',
  Multiverse = 'isMultiverseEnabled',
  Plans = 'isPlansEnabled',
  ProductVariants = 'isProductVariantsEnabled',
  ProductVariantsV2 = 'isProductVariantsV2Enabled',
  Sites = 'isSitesEnabled',
  TerritoryHierarchyDefinition = 'isTerritoryHierarchyDefinitionEnabled',
  IsPrsEnabled = 'isPrsEnabled',
  UserDeviceInfo = 'isUserDeviceInfoEnabled',
  UserDownloadsInfo = 'isUserDownloadsEnabled',
  SiteBuilderFilterByAgeGroups = 'isSiteBuilderFilterByAgeGroupsEnabled',
  SiteBuilderFilterByRoutes = 'isSiteBuilderFilterByRoutesEnabled',
  SiteBuilderFilterByKind = 'isSiteBuilderFilterByKindEnabled',
  SiteBuilderFilterByBrand = 'isSiteBuilderFilterByBrandEnabled',
  SiteBuilderFilterByAudienceTargeting = 'isSiteBuilderFilterByAudienceTargetingEnabled',
  SiteBuilderExcludeTargetForAudiences = 'isSiteBuilderExcludeTargetForAudiencesEnabled',
  ForceOkta = 'isForceOktaEnabled',
  Diana = 'isDianaEnabled',
  StudioPayments = 'isStudioPaymentsEnabled',
  StudioPaymentsRefund = 'isStudioPaymentsRefundEnabled',
  StudioRolesWarning = 'isStudioRolesWarningEnabled',
  AddonProducts = 'isAddonProductsEnabled',
  TestSubscriptions = 'isTestSubscriptionsEnabled',
  LocalizationBulkUpload = 'isLocalizationBulkUploadEnabled',
  MonetizationLegacy = 'isMonetizationLegacyEnabled',
  MonetizationBolt = 'isMonetizationBoltEnabled',
  MVPDDefederation = 'isMVPDDefederationEnabled',
  EnvironmentBanner = 'isEnvironmentBannerEnabled',
  Badges = 'isBadgesEnabled',
  UpdateUserMigration = 'isUpdateUserMigrationEnabled',
  LocalizedImages = 'isLocalizedImagesEnabled',
  LocalizedImagesAppExperience = 'isLocalizedImagesAppExperienceEnabled',
  BoltLocalization = 'isBoltLocalizationEnabled',
  PartnerMapping = 'isPartnerMappingEnabled',
  StudioIdentity = 'isStudioIdentityEnabled',
  DedupeCheckboxes = 'isDedupeCheckboxesEnabled',
  SilhouetteProfile = 'isSilhouetteProfileEnabled',
  Multipass = 'isMultipassEnabled',
  ProfileSimulator = 'isProfileSimulatorEnabled',
  UserAuthProviders = 'isUserAuthProvidersEnabled',
  ElvisLegalFeatureFlag = 'isElvisLegalFeatureEnabled',
  Flash = 'isFlashEnabled',
  GlobalPolicies = 'isGlobalPoliciesEnabled',
  $AppName = 'is$AppNameEnabled',
  NewLocalizationHelperText = 'isNewLocalizationHelperTextEnabled',
  VerticalPinning = 'isVerticalPinningEnabled',
  AuthProviderVerification = 'isAuthProviderVerificationEnabled',
  Mosaic = 'isMosaicEnabled',
  SiteReporting = 'isSiteReportingEnabled',
  CommerceLines = 'isCommerceLinesEnabled',
  Communications = 'isCommunicationsEnabled',
  StudioExperience = 'isStudioExperienceEnabled',
  Users = 'isUsersEnabled',
  ExtraMemberService = 'isExtraMemberServiceEnabled',
  GeoOverrides = 'isGeoOverridesEnabled',
  CurationPromotion = 'isCurationPromotionEnabled',
  PlanBulkActions = 'isPlanBulkActionsEnabled',
  IpWhitelist = 'isIpWhitelistEnabled',
  // 🚨 DON'T REMOVE COMMENT : Register your new feature above
}
