import { createAbortError } from './abortError';

export function delay(ms: number, signal?: AbortSignal | null): Promise<void> {
  signal?.throwIfAborted();

  const { promise, resolve, reject } = Promise.withResolvers<void>();

  const timeoutId = setTimeout(resolve, ms);

  if (signal == null) {
    return promise;
  }

  const handleAbort = () => {
    clearTimeout(timeoutId);
    reject(signal.reason ?? createAbortError());
  };

  signal.addEventListener('abort', handleAbort);

  return promise.finally(() => {
    signal.removeEventListener('abort', handleAbort);
  });
}

// Port of Bluebird.method
export function promiseMethod<Args extends unknown[], T>(
  fn: (...args: Args) => T | PromiseLike<T>,
): (...args: Args) => Promise<T> {
  return (Promise.try<T, Args>).bind(Promise, fn);
}

function waitForValue<K, V>([key, value]: [K, V | PromiseLike<V>]): Promise<
  [K, V]
> {
  return Promise.resolve(value).then(resolvedValue => [key, resolvedValue]);
}

// Port of Bluebird.props
export function promiseProps<T extends object>(
  obj: T,
): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  const entries = Object.entries(obj).map(waitForValue);
  return Promise.all(entries).then(Object.fromEntries) as any;
}

export function serialize<Args extends any[], T>(
  fn: (...args: Args) => T | PromiseLike<T>,
): (...args: Args) => Promise<T> {
  let lastPromise: Promise<T> | undefined;

  async function waitAndRun(args: Args): Promise<T> {
    try {
      await lastPromise;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return await fn(...args);
    }
  }

  return (...args: Args): Promise<T> => {
    lastPromise = waitAndRun(args);
    return lastPromise;
  };
}
