import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

import type { Store } from './useStoreState';

export interface BroadcastStore<out State, in out Action>
  extends Store<State>,
    Disposable {
  get name(): string;
  dispatch(action: Action): void;
}

export type Reducer<in out State, in Action> = (
  state: State,
  action: Action,
) => State;

export default function createBroadcastStore<State, Action>(
  name: string,
  reducer: Reducer<State, Action>,
  initialState: State,
): BroadcastStore<State, Action> {
  let state = initialState;

  const channel = new BroadcastChannel(name);

  const listeners = new Set<() => void>();

  function notifyListeners() {
    for (const listener of listeners) {
      try {
        listener();
      } catch (error) {
        reportError(error);
      }
    }
  }

  function handleMessage({ data: action }: MessageEvent<Action>) {
    state = reducer(state, action);
    batchedUpdates(notifyListeners);
  }

  channel.addEventListener('message', handleMessage);

  return {
    name,

    unsafe_getState() {
      return state;
    },

    dispatch(action) {
      state = reducer(state, action);
      batchedUpdates(notifyListeners);
      channel.postMessage(action);
    },

    subscribe(listener) {
      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    },

    [Symbol.dispose]() {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    },
  };
}
