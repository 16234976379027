import { createBaseRoute, createRoute } from '@studio/router/routes';

export * from '@studio/router/routes';

export const root = createBaseRoute('/');

export const portalRoot = root;
export const contentInsightRoot = createRoute(
  root,
  'content-visualization-beta',
);
export const localizationReportingRoot = createRoute(
  root,
  'localization-reporting',
);
export const studioAccessRoot = createRoute(root, 'studio-access');
export const contentVisualizationRoot = createRoute(
  root,
  'content-visualization',
);
export const vmsRoot = createRoute(root, 'vms-ui');
export const cmsRoot = createRoute(root, 'cms-ui');
export const mmsRoot = createRoute(root, 'mms-ui');
export const umsRoot = createRoute(root, 'ums-ui');
export const labsRoot = createRoute(root, 'labs-ui');
export const ratingsRoot = createRoute(root, 'ratings-ui');
export const tmsRoot = createRoute(root, 'tms-ui');
export const legalRoot = createRoute(root, 'legals-ui');
export const commsRoot = createRoute(root, 'comms-ui');
export const capabilitiesRoot = createRoute(root, 'capabilities-ui');
export const settingsRoot = createRoute(root, 'settings-ui');
export const searchRoot = createRoute(root, 'search');
export const imagesRoot = createRoute(root, 'images-ui');
export const sandboxRoot = createRoute(root, 'sandbox');
export const contentPromotionRoot = createRoute(root, 'promotions');
export const subscriptionJourneyRoot = createRoute(
  root,
  'subscription-journey',
);
export const modelComparisonRoot = createRoute(root, 'model-comparison');
export const configurationRoot = createRoute(root, 'configuration');
export const avatarsRoot = createRoute(root, 'avatars');
export const ppvRoot = createRoute(mmsRoot, 'ppv');
export const gsmRoot = createRoute(root, 'gsm');
export const multiverseRoot = createRoute(root, 'multiverse');
export const dianaRoot = createRoute(root, 'diana');
export const badgesRoot = createRoute(root, 'badges');
export const segmentsRoot = createRoute(root, 'segments');
export const multipassRoot = createRoute(root, 'multipass');
export const profileSimulatorRoot = createRoute(root, 'profile-simulator');
export const flashRoot = createRoute(root, 'flash');
export const globalPoliciesRoot = createRoute(root, 'global-policies');
export const $appNameRoot = createRoute(root, '$app-name');
export const mosaicRoot = createRoute(root, 'mosaic');
export const siteReportingRoot = createRoute(root, 'site-reporting');
export const localizationReportingBetaRoot = createRoute(
  root,
  'localization-reporting-beta',
);
export const curationPromotionRoot = createRoute(root, 'curation-promotion');
