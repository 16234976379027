import createEnumValidator from '@studio/utils/createEnumValidator';
import createLabels from '@studio/utils/createLabels';

export enum Role {
  Admin = 'admin',
  CampaignManager = 'campaignmanager',
  CustomerSupport = 'customersupport',
  Editor = 'editor',
  EditorGlobal = 'editorglobal',
  EditorUCAN = 'editorucan',
  EditorLATAM = 'editorlatam',
  EditorEMEA = 'editoremea',
  EditorAPAC = 'editorapac',
  GSMUIApprover = 'gsmuiapprover',
  Ingest = 'ingest',
  IpWhitelister = 'ipwhitelister',
  GeoOverrider = 'geooverrider',
  Notifier = 'notifier',
  TestSubscriptionsManager = 'testsubscriptionsmanager',
  PackageEditor = 'packageeditor',
  ProductManager = 'productmanager',
  TemplateManager = 'templatemanager',
  UsersAdmin = 'usersadmin',
  LegalManager = 'legalmanager',
  SonicStudioBetaTester = 'sonicstudiobetatester',
  NoRole = 'none',
  SonicDeveloper = 'sonicdeveloper',
  TaxonomyManager = 'taxonomymanager',
  SiteBuilderAdmin = 'sitebuilderadmin',
  CapabilityManager = 'capabilitymanager',
  ReadOnly = 'sitebuilderreadonly',
  LabsAdmin = 'labsadmin',
  LabsManager = 'labsmanager',
  LabsViewer = 'labsviewer',
  LabsViewerAdmin = 'labsvieweradmin',
  RARApprover = 'rarapprover',
  ContractManager = 'contractmanager',
  AccountingEditor = 'accountingeditor',
  BusinessReader = 'businessreader',
  EditorialReader = 'editorialreader',
  ContentPromoter = 'contentpromoter',
  ContentPlayer = 'contentplayer',
  ProductLineEditor = 'productlineeditor',
  PPVManager = 'ppvmanager',
  Multiverse = 'multiverse',
  MultiverseAdmin = 'multiverseadmin',
  DianaCategoryManager = 'dianacategorymanager',
  DianaOverrideManager = 'dianaoverridemanager',
  DianaOverridePublisher = 'dianaoverridepublisher',
  LocalizationManager = 'localizationmanager',
  SegmentManager = 'segmentmanager',
  StudioUserAdmin = 'studiouseradmin',
  PartnerIntegrationManager = 'partnerintegrationmanager',
  StudioIdentityTester = 'studioidentitytester',
  MultiConfigAdmin = 'multiconfigadmin',
  MultiConfigGeographicManager = 'multiconfiggeographicmanager',
  MultiConfigRatingsManager = 'multiconfigratingsmanager',
  SiteBuilderManager = 'sitebuildermanager',
  SiteConfigManager = 'siteconfigmanager',
  MosaicMartechAdmin = 'mosaicmartechadmin',
  MosaicMartechOperator = 'mosaicmartechoperator',
  MosaicMartechOnLooker = 'mosaicmartechonlooker',
  MosaicDataEngineerAdmin = 'mosaicdataengineeradmin',
  MosaicProductManagementAdmin = 'mosaicproductmanagementadmin',
  MosaicProductManagementOperator = 'mosaicproductmanagementoperator',
  MosaicProductManagementOnLooker = 'mosaicproductmanagementonlooker',
  LocalizationReader = 'localizationreader',
  LocalizationWriter = 'localizationwriter',
}

export const { getItemLabel: getRoleName, getItemKey: getRoleKey } =
  createLabels<Role>({
    [Role.Admin]: 'Admin',
    [Role.CampaignManager]: 'Campaign Manager',
    [Role.CustomerSupport]: 'Customer Support',
    [Role.Editor]: 'Editor',
    [Role.EditorGlobal]: 'Editor Global',
    [Role.EditorUCAN]: 'Editor UCAN',
    [Role.EditorLATAM]: 'Editor LATAM',
    [Role.EditorEMEA]: 'Editor EMEA',
    [Role.EditorAPAC]: 'Editor APAC',
    [Role.GSMUIApprover]: 'GSM UI Approver',
    [Role.Ingest]: 'Ingest (Deprecated)',
    [Role.IpWhitelister]: 'IP Manager',
    [Role.GeoOverrider]: 'Geo Overrider',
    [Role.Notifier]: 'Notifier (Deprecated)',
    [Role.TestSubscriptionsManager]: 'Test Subscriptions Manager',
    [Role.PackageEditor]: 'Package Editor',
    [Role.ProductManager]: 'Product Manager',
    [Role.TemplateManager]: 'Template Manager',
    [Role.UsersAdmin]: 'User Admin',
    [Role.LegalManager]: 'Legal Manager',
    [Role.SonicStudioBetaTester]: 'Sonic Studio Beta Tester',
    [Role.NoRole]: 'No Role',
    [Role.SonicDeveloper]: 'Sonic Developer',
    [Role.TaxonomyManager]: 'Taxonomy Manager',
    [Role.SiteBuilderAdmin]: 'Site Builder Admin',
    [Role.CapabilityManager]: 'Capability Manager',
    [Role.ReadOnly]: 'Read Only',
    [Role.LabsAdmin]: 'Labs Admin',
    [Role.LabsManager]: 'Labs Manager',
    [Role.LabsViewer]: 'Labs Viewer',
    [Role.LabsViewerAdmin]: 'Labs Viewer Admin',
    [Role.RARApprover]: 'RAR Approver',
    [Role.ContractManager]: 'Contract Manager',
    [Role.AccountingEditor]: 'Accounting Editor',
    [Role.BusinessReader]: 'Business Reader',
    [Role.EditorialReader]: 'Editorial Reader',
    [Role.ContentPromoter]: 'Content Promoter',
    [Role.ContentPlayer]: 'Content Player',
    [Role.ProductLineEditor]: 'Product Line Editor',
    [Role.PPVManager]: 'PPV Manager',
    [Role.Multiverse]: 'Multiverse',
    [Role.MultiverseAdmin]: 'Multiverse Admin',
    [Role.DianaCategoryManager]: 'DIANA Category Manager',
    [Role.DianaOverrideManager]: 'DIANA Override Manager',
    [Role.DianaOverridePublisher]: 'DIANA Override Publisher',
    [Role.LocalizationManager]: 'Localization Manager',
    [Role.SegmentManager]: 'Segment Manager',
    [Role.StudioUserAdmin]: 'Studio User Admin',
    [Role.PartnerIntegrationManager]: 'Partner Integration Manager',
    [Role.StudioIdentityTester]: 'Studio Identity Tester',
    [Role.MultiConfigAdmin]: 'Multiconfig Admin',
    [Role.MultiConfigGeographicManager]: 'Multiconfig Geographic Manager',
    [Role.MultiConfigRatingsManager]: 'Multiconfig Ratings Manager',
    [Role.SiteBuilderManager]: 'Site Builder Manager',
    [Role.SiteConfigManager]: 'Site Config Manager',
    [Role.MosaicMartechAdmin]: 'Mosaic Martech Admin',
    [Role.MosaicMartechOperator]: 'Mosaic Martech Operator',
    [Role.MosaicMartechOnLooker]: 'Mosaic Martech OnLooker',
    [Role.MosaicDataEngineerAdmin]: 'Mosaic Data Engineering Admin',
    [Role.MosaicProductManagementAdmin]: 'Mosaic Product Management Admin',
    [Role.MosaicProductManagementOperator]:
      'Mosaic Product Management Operator',
    [Role.MosaicProductManagementOnLooker]:
      'Mosaic Product Management OnLooker',
    [Role.LocalizationReader]: 'Localization Reader',
    [Role.LocalizationWriter]: 'Localization Writer',
  });

export const ALL_ROLES = Object.values(Role).sort() as readonly Role[];

export const isRole = createEnumValidator(Role);

export enum RoleOwner {
  Shilan = 'Shilan Heravi',
  Anton = 'Anton Westman',
  Peder = 'Peder Krogh',
  Christy = 'Christy Miller',
  Eric = 'Eric Hernandez',
  Deepna = 'Deepna Devkar',
  Ananda = 'Ananda Sinha',
  Jim = 'Jim Anderson',
  Jonas = 'Jonas Karstrom',
  Venkatesh = 'Venkatesh Mula',
  Deprecated = 'Deprecated',
}

export const { getItemLabel: getRoleOwner } = createLabels<Role>({
  [Role.Admin]: RoleOwner.Anton,
  [Role.CampaignManager]: RoleOwner.Peder,
  [Role.TestSubscriptionsManager]: RoleOwner.Jonas,
  [Role.PackageEditor]: RoleOwner.Peder,
  [Role.ProductManager]: RoleOwner.Peder,
  [Role.TemplateManager]: RoleOwner.Peder,
  [Role.PPVManager]: RoleOwner.Peder,
  [Role.CustomerSupport]: RoleOwner.Eric,
  [Role.GeoOverrider]: RoleOwner.Eric,
  [Role.Editor]: RoleOwner.Christy,
  [Role.EditorGlobal]: RoleOwner.Christy,
  [Role.EditorUCAN]: RoleOwner.Christy,
  [Role.EditorLATAM]: RoleOwner.Christy,
  [Role.EditorEMEA]: RoleOwner.Christy,
  [Role.EditorAPAC]: RoleOwner.Christy,
  [Role.GSMUIApprover]: RoleOwner.Anton,
  [Role.Ingest]: RoleOwner.Deprecated,
  [Role.IpWhitelister]: RoleOwner.Shilan,
  [Role.Notifier]: RoleOwner.Deprecated,
  [Role.UsersAdmin]: RoleOwner.Shilan,
  [Role.LegalManager]: RoleOwner.Jim,
  [Role.SonicStudioBetaTester]: RoleOwner.Anton,
  [Role.NoRole]: RoleOwner.Deprecated,
  [Role.SonicDeveloper]: RoleOwner.Anton,
  [Role.TaxonomyManager]: RoleOwner.Christy,
  [Role.SiteBuilderAdmin]: RoleOwner.Deprecated,
  [Role.CapabilityManager]: RoleOwner.Peder,
  [Role.ReadOnly]: RoleOwner.Deprecated,
  [Role.LabsAdmin]: RoleOwner.Deepna,
  [Role.LabsManager]: RoleOwner.Deepna,
  [Role.LabsViewer]: RoleOwner.Deepna,
  [Role.LabsViewerAdmin]: RoleOwner.Deepna,
  [Role.RARApprover]: RoleOwner.Peder,
  [Role.ContractManager]: RoleOwner.Peder,
  [Role.AccountingEditor]: RoleOwner.Peder,
  [Role.BusinessReader]: RoleOwner.Peder,
  [Role.EditorialReader]: RoleOwner.Christy,
  [Role.ContentPromoter]: RoleOwner.Christy,
  [Role.ContentPlayer]: RoleOwner.Christy,
  [Role.ProductLineEditor]: RoleOwner.Christy,
  [Role.Multiverse]: RoleOwner.Ananda,
  [Role.MultiverseAdmin]: RoleOwner.Ananda,
  [Role.DianaCategoryManager]: RoleOwner.Ananda,
  [Role.DianaOverrideManager]: RoleOwner.Ananda,
  [Role.DianaOverridePublisher]: RoleOwner.Ananda,
  [Role.LocalizationManager]: RoleOwner.Anton,
  [Role.SegmentManager]: RoleOwner.Deepna,
  [Role.StudioUserAdmin]: RoleOwner.Anton,
  [Role.PartnerIntegrationManager]: RoleOwner.Anton,
  [Role.StudioIdentityTester]: RoleOwner.Anton,
  [Role.MultiConfigAdmin]: RoleOwner.Ananda,
  [Role.MultiConfigGeographicManager]: RoleOwner.Ananda,
  [Role.MultiConfigRatingsManager]: RoleOwner.Ananda,
  [Role.SiteBuilderManager]: RoleOwner.Christy,
  [Role.SiteConfigManager]: RoleOwner.Deepna,
  [Role.MosaicMartechAdmin]: RoleOwner.Venkatesh,
  [Role.MosaicMartechOperator]: RoleOwner.Venkatesh,
  [Role.MosaicMartechOnLooker]: RoleOwner.Venkatesh,
  [Role.MosaicDataEngineerAdmin]: RoleOwner.Venkatesh,
  [Role.MosaicProductManagementAdmin]: RoleOwner.Venkatesh,
  [Role.MosaicProductManagementOperator]: RoleOwner.Venkatesh,
  [Role.MosaicProductManagementOnLooker]: RoleOwner.Venkatesh,
  [Role.LocalizationReader]: RoleOwner.Anton,
  [Role.LocalizationWriter]: RoleOwner.Anton,
});
