import { type Dispatch, type Reducer, useLayoutEffect, useState } from 'react';

import { createAbortError, isAbortError } from '@studio/utils/abortError';
import {
  type RequestContext,
  useRequestContext_UNSAFE,
} from '@studio/utils/requestContext';
import useBroadcastChannel from '@studio/utils/useBroadcastChannel';
import useEventCallback from '@studio/utils/useEventCallback';

export async function waitUntilVisible(signal: AbortSignal): Promise<void> {
  signal.throwIfAborted();

  if (!document.hidden) {
    return;
  }

  const { resolve, reject, promise } = Promise.withResolvers<void>();

  const handleAbort = () => {
    reject(signal.reason ?? createAbortError());
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      resolve();
    }
  };

  signal.addEventListener('abort', handleAbort);
  document.addEventListener('visibilitychange', handleVisibilityChange);

  try {
    await promise;
  } finally {
    signal.removeEventListener('abort', handleAbort);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
}

export function createStateMachineReducer<
  State extends { readonly status: string },
  Action extends { readonly type: string },
>(transitionMap: {
  [Status in State['status']]?: {
    [Type in Action['type']]?: (
      state: Extract<State, { readonly status: Status }>,
      action: Extract<Action, { readonly type: Type }>,
    ) => State;
  };
}): Reducer<State, Action> {
  return function stateMachineReducer(state, action) {
    // @ts-expect-error - generics...
    return transitionMap[state.status]?.[action.type]?.(state, action) ?? state;
  };
}

export function makeStateMachineEffects<
  State extends { readonly status: string },
  Action extends { readonly type: string },
>(stateHandlers: {
  [Status in State['status']]?: (
    context: RequestContext,
    state: Extract<State, { readonly status: Status }>,
    dispatch: Dispatch<Action>,
  ) => PromiseLike<Action | undefined | void>;
}) {
  return function useStateMachineEffects(
    state: State,
    dispatch: Dispatch<Action>,
  ): void {
    const context = useRequestContext_UNSAFE();
    const [error, setError] = useState<unknown>();

    // Bubble unhandled errors
    if (error !== undefined) {
      throw error;
    }

    useLayoutEffect(() => {
      const handler = stateHandlers[state.status as State['status']];

      if (handler === undefined) {
        return undefined;
      }

      const abortController = new AbortController();

      const signal = AbortSignal.any([context.signal, abortController.signal]);

      (async () => {
        try {
          const maybeAction = await handler(
            { ...context, signal },
            state as any,
            dispatch,
          );
          signal.throwIfAborted();
          if (maybeAction !== undefined) {
            dispatch(maybeAction);
          }
        } catch (requestError) {
          if (!isAbortError(requestError, signal)) {
            abortController.abort();
            setError(requestError);
          }
        }
      })();

      return () => {
        abortController.abort();
      };
    }, [state, dispatch, context]);
  };
}

// Cross-tab action sync
export function useSynchronizedDispatch<Action>(
  dispatch: Dispatch<Action>,
  channelName: string,
  actionToSend: (action: Action) => Action | null,
): Dispatch<Action> {
  const { postMessage } = useBroadcastChannel<Action>({
    channelName,
    onMessage: dispatch,
  });

  const wrappedDispatch = useEventCallback<Dispatch<Action>>(action => {
    const handledAction = actionToSend(action);
    if (handledAction !== null) {
      postMessage(handledAction);
    }
    dispatch(action);
  });

  return wrappedDispatch;
}
