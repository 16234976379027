import subscribe from './subscribe';

type LockAcquiredCallback<T> = (lock: Lock | null) => T | PromiseLike<T>;

interface LockRequest<T> {
  readonly mode: LockMode;
  readonly callback: LockAcquiredCallback<T>;
  readonly resolve: (value: Promise<T>) => void;
  readonly removeSignalHandler: (() => void) | undefined;
}

class LocalNamedLockManager {
  readonly name: string;
  readonly #locks = new Set<Lock>();
  readonly #queue: Array<LockRequest<any>> = [];

  get locks(): ReadonlySet<Lock> {
    return this.#locks;
  }

  get queue(): ReadonlyArray<LockRequest<any>> {
    return this.#queue;
  }

  constructor(name: string) {
    this.name = name;
  }

  request<T>(
    options: LockOptions,
    callback: LockAcquiredCallback<T>,
  ): Promise<T> {
    return new Promise(resolve => {
      const {
        mode = 'exclusive',
        ifAvailable = false,
        steal = false,
        signal,
      } = options;

      const lockRequest: LockRequest<T> = {
        mode,
        callback,
        resolve,
        removeSignalHandler:
          signal === undefined
            ? undefined
            : subscribe(signal, 'abort', () => {
                const index = this.#queue.indexOf(lockRequest);
                if (index !== -1) {
                  resolve(Promise.reject(signal.reason));
                  this.#queue.splice(index, 1);
                  this.#processQueue();
                }
              }),
      };

      if (steal) {
        this.#locks.clear();
        this.#queue.unshift(lockRequest);
        this.#processQueue();
      } else if (ifAvailable && !this.#lockRequestGrantable(lockRequest)) {
        resolve(Promise.try(callback, null));
      } else {
        this.#queue.push(lockRequest);
        this.#processQueue();
      }
    });
  }

  #lockRequestGrantable<T>(lockRequest: LockRequest<T>): boolean {
    // If queue is not empty and request is not the first item in queue, then return false.
    if (this.queue.length !== 0 && this.queue[0] !== lockRequest) {
      return false;
    }

    // If mode is "exclusive", then return true if no locks are held.
    if (lockRequest.mode === 'exclusive') {
      return this.locks.size === 0;
    }

    // Otherwise, mode is "shared"; return true if no held lock has mode "exclusive", and false otherwise.
    for (const lock of this.locks) {
      if (lock.mode === 'exclusive') {
        return false;
      }
    }

    return true;
  }

  #processQueue(): void {
    // As soon as we reach an ungrantable request, all following requests are ungrantable.
    while (
      this.#queue[0] !== undefined &&
      this.#lockRequestGrantable(this.#queue[0])
    ) {
      const { mode, callback, resolve, removeSignalHandler } =
        this.#queue.shift()!;

      removeSignalHandler?.();

      const lock: Lock = {
        name: this.name,
        mode,
      };

      this.#locks.add(lock);

      const result = Promise.try(callback, lock).finally(() => {
        this.#locks.delete(lock);
        this.#processQueue();
      });

      resolve(result);
    }
  }
}

export default class LocalLockManager implements LockManager {
  readonly #namedLockManagers = new Map<string, LocalNamedLockManager>();

  query(): Promise<LockManagerSnapshot> {
    const held: LockInfo[] = [];
    const pending: LockInfo[] = [];

    for (const [name, namedLockManager] of this.#namedLockManagers) {
      for (const lock of namedLockManager.locks) {
        held.push({ name, mode: lock.mode });
      }
      for (const request of namedLockManager.queue) {
        pending.push({ name, mode: request.mode });
      }
    }

    return Promise.resolve({ held, pending });
  }

  async request<T>(
    name: string,
    optionsOrCallback: LockOptions | LockAcquiredCallback<T>,
    callback?: LockAcquiredCallback<T>,
  ): Promise<T> {
    let options: LockOptions;

    if (typeof callback === 'function') {
      if (typeof optionsOrCallback === 'function') {
        throw new TypeError('Double callback');
      }
      options = optionsOrCallback;
    } else {
      if (typeof optionsOrCallback !== 'function') {
        throw new TypeError('Missing callback');
      }
      options = {};
      callback = optionsOrCallback;
    }

    // If name starts with U+002D HYPHEN-MINUS (-), then return a promise rejected with a "NotSupportedError" DOMException.
    if (name.startsWith('-')) {
      throw new DOMException('Not supported', 'NotSupportedError');
    }

    // If both options["steal"] and options["ifAvailable"] are true, then return a promise rejected with a "NotSupportedError" DOMException.
    if (options.steal === true && options.ifAvailable === true) {
      throw new DOMException('Not supported', 'NotSupportedError');
    }

    // If options["steal"] is true and options["mode"] is not "exclusive", then return a promise rejected with a "NotSupportedError" DOMException.
    if (
      options.steal === true &&
      options.mode !== undefined &&
      options.mode !== 'exclusive'
    ) {
      throw new DOMException('Not supported', 'NotSupportedError');
    }

    // If options["signal"] exists, and either of options["steal"] or options["ifAvailable"] is true, then return a promise rejected with a "NotSupportedError" DOMException.
    if (
      options.signal != null &&
      (options.steal === true || options.ifAvailable === true)
    ) {
      throw new DOMException('Not supported', 'NotSupportedError');
    }

    // If options["signal"] exists and is aborted, then return a promise rejected with options["signal"]'s abort reason.
    options.signal?.throwIfAborted();

    let namedLockManager = this.#namedLockManagers.get(name);
    if (namedLockManager === undefined) {
      namedLockManager = new LocalNamedLockManager(name);
      this.#namedLockManagers.set(name, namedLockManager);
    }

    return await namedLockManager.request(options, callback);
  }
}
