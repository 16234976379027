import { matchPath } from '@studio/router';

import {
  $appNameRoot,
  avatarsRoot,
  badgesRoot,
  capabilitiesRoot,
  cmsRoot,
  commsRoot,
  configurationRoot,
  contentInsightRoot,
  contentPromotionRoot,
  contentVisualizationRoot,
  curationPromotionRoot,
  dianaRoot,
  flashRoot,
  globalPoliciesRoot,
  gsmRoot,
  labsRoot,
  legalRoot,
  localizationReportingBetaRoot,
  localizationReportingRoot,
  mmsRoot,
  modelComparisonRoot,
  mosaicRoot,
  multipassRoot,
  multiverseRoot,
  portalRoot,
  ppvRoot,
  profileSimulatorRoot,
  ratingsRoot,
  sandboxRoot,
  segmentsRoot,
  settingsRoot,
  siteReportingRoot,
  subscriptionJourneyRoot,
  tmsRoot,
  umsRoot,
  vmsRoot,
  // 🚨 DON'T REMOVE COMMENT : app route import from script
} from '../appRoutes';

const appNames = [
  { route: vmsRoot.pattern, name: 'Videos' },
  { route: cmsRoot.pattern, name: 'Site Builder' },
  // Pay-per-view should be before Monetization in this list because
  // matchPath will return the first matched route, i.e. between /mms-ui/ppv
  // and /mms-ui it'll return the first one it will encounter. So the more specific
  // routes should go before the more general
  { route: ppvRoot.pattern, name: 'Pay-per-view' },
  { route: mmsRoot.pattern, name: 'Monetization' },
  { route: umsRoot.pattern, name: 'Users' },
  { route: labsRoot.pattern, name: 'Labs' },
  { route: ratingsRoot.pattern, name: 'Ratings' },
  { route: tmsRoot.pattern, name: 'Taxonomy' },
  { route: legalRoot.pattern, name: 'Legal' },
  { route: commsRoot.pattern, name: 'Communications' },
  { route: capabilitiesRoot.pattern, name: 'Capabilities' },
  { route: settingsRoot.pattern, name: 'Settings' },
  { route: sandboxRoot.pattern, name: 'Sandbox' },
  { route: contentPromotionRoot.pattern, name: 'Content Promotion' },
  { route: contentInsightRoot.pattern, name: 'Content Visualization (Beta)' },
  {
    route: contentVisualizationRoot.pattern,
    name: 'Content Visualization',
  },
  { route: subscriptionJourneyRoot.pattern, name: 'Subscription Journey' },
  { route: gsmRoot.pattern, name: 'Global String Manager' },
  { route: avatarsRoot.pattern, name: 'Avatars' },
  { route: modelComparisonRoot.pattern, name: 'Model Comparison' },
  { route: configurationRoot.pattern, name: 'Configuration' },
  { route: localizationReportingRoot.pattern, name: 'Localization Reporting' },
  { route: multiverseRoot.pattern, name: 'Multiverse' },
  { route: dianaRoot.pattern, name: 'Diana' },
  { route: badgesRoot.pattern, name: 'Badges' },
  { route: segmentsRoot.pattern, name: 'Segments' },
  { route: multipassRoot.pattern, name: 'Multipass' },
  { route: profileSimulatorRoot.pattern, name: 'Profile Simulator' },
  { route: flashRoot.pattern, name: 'Flash' },
  { route: globalPoliciesRoot.pattern, name: 'Global Policies' },
  { route: $appNameRoot.pattern, name: '$App Name' },
  { route: mosaicRoot.pattern, name: 'Mosaic' },
  { route: siteReportingRoot.pattern, name: 'Site Reporting' },
  {
    route: localizationReportingBetaRoot.pattern,
    name: 'Localization Reporting Beta',
  },
  { route: curationPromotionRoot.pattern, name: 'Global Content Promotion' },
  // 🚨 DON'T REMOVE COMMENT : app route pattern from script

  // The same logic applies here. The more general routes should go
  // after the more specific ones. That's why Portal which is just '/'
  // should go after all the other routes
  { route: portalRoot.pattern, name: 'Portal' },
];

export function getAppNameFromPath(pathname: string): string {
  return (
    appNames.find(appName => matchPath(pathname, appName.route) !== null)
      ?.name ?? 'Unknown'
  );
}
